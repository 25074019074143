import React from 'react'

import Loader from '@fullstakk/fms-frontend-loader'
import '@fullstakk/fms-frontend-loader/dist/index.css'

/**
 * " Loader " layout.
 */
export default class LayoutLoader extends React.Component {
    /**
     * Renders the layout.
     */
    render() {
      return (
        <div className='body body-loader'>
          <Loader />
        </div>
      )
    }
}
